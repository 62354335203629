/** @format */

.container {
	padding: 40px;
}

.tab {
	color: #15124e !important;
	font-size: 1.6rem !important;
}

.appBar {
	box-shadow: none !important;
	border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
}
