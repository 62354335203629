/** @format */

.candidateContainer {
	box-shadow: 0 0 20px 0 rgba(108, 99, 255, 0.1) !important;
	border-radius: 15px;
	& > div {
		&:nth-of-type(1) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			& > div {
				display: flex;
				align-items: center;
				.moreIcon {
					font-size: 3rem !important;
					cursor: pointer;
				}
			}

			padding: 1.6rem 3.2rem;
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.12);
			p {
				margin-left: 1rem;
				font-size: 1.6rem;
				font-family: "Nunito", sans-serif;
				color: #212121;
				font-weight: 600;
				&.active {
					color: #2196f3;
				}
			}
			img {
				width: 40px;
				height: 40px;
			}
		}
	}
	.candidateDetails {
		padding: 1.6rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 3rem;
		font-size: 1.5rem;
		font-family: "Nunito", sans-serif;
		@media screen and (max-width: 1400px) {
			font-size: 1.3rem;
			gap: 1.3rem;
		}
		.detailTitle {
			font-weight: 600;
			color: #000000de;
			&.active {
				color: #2196f3;
			}
		}
		.detailValue {
			font-weight: 400;
			color: #000000de;
			&.active {
				color: #2196f3;
			}
		}
	}
}
.menuItem {
	font-size: 1.6rem !important;
}
