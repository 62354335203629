/** @format */

.candidatesContainer {
	padding: 10rem 5rem 5rem 5rem;
	.bucketOptions {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 3rem;
		p {
			font-size: 1.6rem;
			text-align: center;
			font-family: "Raleway", sans-serif;
			font-weight: 700;
			line-height: 0.8;
			padding: 16px 0;
			border-radius: 5px;
			cursor: pointer;
			&:nth-of-type(1) {
				color: #4caf50;
				border: 2px solid #4caf50;
				&:hover,
				&.active {
					background-color: #4caf50;
					color: white;
				}
			}
			&:nth-of-type(2) {
				color: #f44336;
				border: 2px solid #f44336;
				&:hover,
				&.active {
					background-color: #f44336;
					color: white;
				}
			}
			&:nth-of-type(3) {
				color: #f57f17;
				border: 2px solid #f57f17;
				&:hover,
				&.active {
					background-color: #f57f17;
					color: white;
				}
			}
			&:nth-of-type(4) {
				border: 2px solid #dc004e;
				color: #dc004e;
				&:hover,
				&.active {
					background-color: #dc004e;
					color: white;
				}
			}
			&:nth-of-type(5) {
				border: 2px solid #636cff;
				color: #636cff;
				&:hover,
				&.active {
					background-color: #636cff;
					color: white;
				}
			}
			&:nth-of-type(6) {
				border: 2px solid #15124e;
				color: #15124e;
				&:hover,
				&.active {
					background-color: #15124e;
					color: white;
				}
			}
		}
	}
	.candidateViewer {
		margin-top: 5rem;
		& > h2 {
			font-size: 3rem;
			font-family: "Raleway", sans-serif;
			color: #15124e;
		}
		.candidatesList {
			& > h2 {
				text-align: center;
				font-size: 1.8rem;
				font-family: "Raleway", sans-serif;
			}
			&.centerError {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			&.center {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			margin-top: 5rem;
			display: grid;
			gap: 3rem;
			grid-template-columns: repeat(auto-fit, minmax(35rem, 40rem));
			@media screen and (max-width: 1400px) {
				gap: 2rem;
				grid-template-columns: repeat(auto-fit, minmax(27rem, 27rem));
			}
		}
	}
}

.header {
	background-color: #6c63ff;
	height: 70px;
	border-left: 1px solid black;
	padding: 0 5rem;
	display: flex;
	align-items: center;
	position: fixed;
	width: calc(100% - 87px);

	.inputContainer {
		background-color: white;
		padding: 1rem 2rem;
		width: 15%;
		border-radius: 100px;
		display: flex;
		min-width: 200px;
		&:nth-of-type(2) {
			margin-left: 2rem;
			padding: 0.6rem 2rem;
		}
		& input {
			font-size: 1.4rem;
			width: 100% !important;
			border: none;
			outline: none;
		}
	}
	.buttonContainer {
		margin-left: auto;
		background-color: white;
		padding: 0rem;
		border-radius: 5px;

		button {
			&.active {
				background: rgba($color: grey, $alpha: 0.4);
			}
			span {
				padding: 0.6rem 0;
				svg {
					font-size: 3rem !important;
				}
			}
		}
	}
}
.formControl {
	width: 100%;

	.select {
		font-size: 1.4rem !important;
		color: rgba($color: #000000, $alpha: 0.5) !important;
	}
}
.menuitem {
	font-size: 1.6rem !important;
}

.candidatesGrid {
	padding: 10rem 5rem 5rem 5rem;
	display: grid;
	gap: 3rem;
	grid-template-columns: repeat(auto-fit, minmax(35rem, 40rem));
	@media screen and (max-width: 1400px) {
		gap: 2rem;
		grid-template-columns: repeat(auto-fit, minmax(27rem, 27rem));
	}
}
.loading {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	border-radius: 50%;
	border: 3px solid #f3f7ff;
	border-top: 3px solid #636cff;
	animation: rotate 1s infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
