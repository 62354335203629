/** @format */

.container {
	padding: 40px;
}

.uploadCard {
	box-shadow: 0 0 30px 0 rgba(108, 99, 255, 0.1);
	padding: 30px;
	border-radius: 10px;
	background: white;
	p {
		font-size: 25px;
		font-weight: 700;
		font-family: "Raleway", sans-serif;
		color: #15124e;
		word-wrap: break-word;
		line-height: 1.5;
	}
	span {
		font-size: 15px;
		color: #404040;
		font-family: "Nunito", sans-serif;
		line-height: 1.5;
	}
}
.importBTN {
	display: flex;
	margin-top: 2rem;
	input {
		display: none;
	}
	label {
		cursor: pointer;
		padding: 1rem 5rem;
		font-size: 1.6rem;
		font-family: "Nunito", sans-serif;
		font-weight: 700;
		color: rgb(108, 99, 255);
		border: 2px solid rgb(108, 99, 255);
		border-radius: 5px;
	}
}

.info {
	margin-top: 5rem;
	& > div {
		display: flex;
		align-items: center;
		font-size: 35px !important;
		font-family: "Nunito", sans-serif;
		color: #15124e;
		.icon {
			font-size: 45px !important;
			color: #000;
			margin-left: 0.6rem;
		}
	}
	& > span {
		font-family: "Nunito", sans-serif;
		font-size: 1.6rem;
		color: rgba(0, 0, 0, 0.87);
	}
}
