/** @format */

.modal {
	padding: 3rem;
	& > div {
		&:nth-of-type(1) {
			display: flex;
			align-items: center;

			img {
				width: 80px;
			}
			& > div {
				margin-left: 2rem;
				p {
					font-size: 1.6rem;
					font-family: "Nunito", sans-serif;
					&:not(:last-child) {
						margin-bottom: 0.5rem;
					}
					& > span {
						font-weight: 600;
					}
				}
				&:last-child {
					margin-left: auto;
					margin-right: 5rem;
				}
				.fab {
					background: #d11a2a;
					align-self: center;
					margin-left: 5rem;
					color: white !important;
					padding-right: 2rem;
					padding-left: 2rem;
					&:hover {
						background: #d11a2a !important;
					}
					.fabIcon {
						font-size: 3rem !important;
						color: #fff;
					}
				}
			}
		}
	}
	.candidate {
		margin-top: 3rem;
	}
}
/** @format */

.tab {
	color: #6c63ff !important;
	font-size: 1.6rem !important;
	height: 40px;
}
.panelChild {
	padding: 2rem;
	// height: 480px;
	min-height: 480px;
	max-height: 480px;

	&.chatbox {
		background: #f3f6ff;
	}
	& > .profile {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}
.chatBox {
	background: #f3f7ff;
	overflow-y: auto;
	&.chatBoxLoading {
		align-items: center;
		justify-content: center;
		display: flex;
	}
	h5 {
		text-align: center;
		font-family: "Raleway", sans-serif;
		font-size: 2rem;
		margin-top: 2rem;
	}
	.chatSubBox {
		margin: 2rem 1rem;
		display: flex;
		flex-direction: column;
		// height: 480px;

		.chatContainer {
			font-family: "Nunito", sans-serif;
			align-self: flex-end;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			margin-bottom: 1rem;
			& > div {
				margin-left: 1rem;
				align-self: flex-end;
			}
			& > p {
				font-size: 1.6rem;
				border-radius: 5px;
				background-color: #fff;
				padding: 1rem;
				max-width: 70%;
			}
		}
		.chatContainerBot {
			font-family: "Nunito", sans-serif;
			align-self: flex-start;
			display: flex;
			align-items: center;
			& > div {
				margin-right: 1rem;
				align-self: flex-end;
			}
			& > p {
				font-size: 1.6rem;
				border-radius: 5px;
				background-color: #fff;
				padding: 1rem;
				max-width: 70%;
			}

			margin-bottom: 1.5rem;
		}
	}
}
.icon {
	img {
		width: 40px;
		height: 40px;
	}
}

.iconUser {
	img {
		width: 20px;
		height: 20px;
	}
}

.iconBot {
	img {
		width: 20px;
		height: 20px;
	}
}
.formFiledContainer {
	padding: 10px 1rem;
	margin-bottom: 1.5rem;
	border-radius: 10px;
	display: flex;
	align-items: center;
	font-family: "Nunito", sans-serif;
	position: relative;

	& > div {
		width: 50%;
	}

	label {
		&.alignTop {
			align-self: flex-start;
		}
		width: 20%;
		font-size: 1.6rem;
	}
	.addButton {
		position: absolute;
		right: -20px;
	}
}
.closeButton {
	position: absolute;
	cursor: pointer;
	right: 10px;
	top: 5px;
}
.deleteModal {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	p {
		font-size: 1.8rem;
		font-family: "Nunito", sans-serif;
		font-weight: 600;
	}
	& > div {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
		margin-top: 3rem;
		button {
			width: 30%;
			&:nth-of-type(1) {
				background-color: #d11a2a !important;
				color: #fff;
			}
			&:nth-of-type(2) {
				border-color: #d11a2a;
				color: #d11a2a;
			}
		}
	}
}
.editNote {
	button {
		margin-top: 2rem;
		background-color: #6c63ff !important;
	}
}
.resume {
	margin-top: 3rem;
	h3 {
		font-size: 1.8rem;
		font-family: "Nunito", sans-serif;
		color: #272380;
	}

	.resumeIcon {
		font-size: 3rem !important;
	}
	.resumeContainer {
		margin-top: 1rem;
		padding: 1rem;
		display: flex;
		align-items: center;
		background-color: #f3f6ff;
		h5 {
			font-size: 1.4rem;
			font-family: "Nunito", sans-serif;
			text-align: center;
			width: 100%;
		}
		.resumeLink {
			color: #636cff;
		}
	}
}
