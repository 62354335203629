.container {
  min-height: 100vh;
  width: calc(100% - 87px);
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5rem;
  margin-left: 87px;
  padding: 10rem 20rem;
  font-size: 20px;
  font-weight: 400;
  font-family: "inter", sans-serif;
  form {
    display: flex;
    align-items: center;
    // flex-direction: column;
    column-gap: 2rem;
    input {
      font-size: large;
      padding: 10px 20px;
      border-radius: 4px;
      border: 1px solid #fff;
      background-color: #e7e7e7;
      -webkit-transition: 0.5s;
      text-align: center;
      transition: 0.5s;
    }
    input:focus {
      outline: none;
      border: 1px solid rgb(168, 168, 168);
      // box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }
  }
}
