.MuiTablePagination-toolbar {
  font-size: 16px;
}
.MuiTablePagination-selectLabel {
  font-size: 16px !important;
}
.css-19c58e0-MuiTablePagination-displayedRows {
  font-size: 16px !important;
}

.dropzone {
  background-color: #f9fafb;
  border-radius: 12px;
  border: 1px dashed eaecf0;
  padding: 16px 24px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  row-gap: 12px;
  flex-direction: column;
  cursor: pointer;
}
.dropzone h4 {
  color: #6941c6;
}
