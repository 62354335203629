/** @format */

.drawer {
  display: flex;
}

.drawer > div {
  width: 15%;
  // background: #6c63ff !important;
  background: #fff;
  color: #344054;
  transition: all 0.5s ease !important;
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0 !important;
  overflow: hidden;
  border: none;
  border-right: 1px solid #eaeaea;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  img {
    width: 40px;
  }
}
.menu {
  z-index: 1201;
  margin-top: 28px !important;
}
.button {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;

  .nav-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
    cursor: pointer;
    padding: 8px 16px !important;
    &.active {
      background-color: white;
      border-color: white;
      p {
        // color: #6c63ff;
        color: #344054;
      }
      .icon {
        // color: #6c63ff;
        color: #344054;
      }
    }
    p {
      text-transform: none !important;
      // color: #fff;
      color: #344054;
      font-size: 1.3rem;
      padding: 3px 0 0 0;
      font-family: "Nunito", sans-serif;
      font-weight: 500;
    }
  }
}
.icon {
  color: #667085;
  font-size: 24px !important;
}

.bottom-nav {
  margin-top: auto !important;
}
.bottom-nav-item {
  display: flex;
  align-items: center;
  column-gap: 16px;
  p {
    font-size: 1.3rem;
    padding: 3px 0 0 0;
    font-family: "Nunito", sans-serif;
    color: #667085;
  }
  &:first-child {
    border-bottom: 1px solid #8881ff;
    border-top: 1px solid #8881ff;
  }
  &:last-child {
    // text-transform: uppercase;
    cursor: pointer;
  }
  &:last-child :hover {
    color: #b42318;
  }
}
.deleteModal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 1.8rem;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
  }
  & > div {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 3rem;
    button {
      width: 30%;
      &:nth-of-type(1) {
        background-color: #6c63ff !important;
        color: #fff;
      }
      &:nth-of-type(2) {
        border-color: #6c63ff;
        color: #6c63ff;
      }
    }
  }
}

.nestedModal {
  // your existing styles

  .modalHeader {
    display: flex;
    justify-content: flex-end;
    height: 10px;

  }

  .closeButton {
    margin: 10px;
  }

  .modalContent {
    // styles for the modal content
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    p {
      font-size: 20px;
      margin-bottom: 20px;
    }

   
video {
  width: 100%;
  max-width: 760px;
  height: 500px;  
  border-radius: 12px;
}
  }
}

