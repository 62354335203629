/** @format */

.container {
	height: 100vh;
	display: flex;
}
.bar {
	border-right: 3px solid #f3f7ff;
}
.chatContainer {
	.messageBar {
		border-bottom: 1px solid #f3f7ff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h2 {
			font-size: 15px;
			font-family: "Raleway", sans-serif;
			color: #15124e;
			padding: 20px 15px;
		}
		& > div {
			padding-right: 50px;
			display: flex;
			align-items: center;
			& > *:not(:last-child) {
				margin-right: 1rem;
			}
			svg {
				font-size: 3rem !important;
			}
			.icon {
				img {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}
