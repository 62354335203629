/** @format */

.jobItem {
	padding: 10px 15px;
	display: flex;
	border-bottom: 1px solid #e9f0ff;
	cursor: pointer;
	&:hover {
		background: #f3f6ff;
	}

	.appliedNum {
		font-family: "Nunito", sans-serif;

		padding: 1rem;
		background: #9e77ed;
		border-radius: 50%;
		color: #fff;
		font-size: 1.2rem;
		margin-right: 15px;
		align-self: flex-start;
	}
	.detail {
		flex-grow: 1;
		p {
			font-family: "Raleway", sans-serif;
			&:nth-of-type(1) {
				font-size: 1.5rem;
				font-weight: 600;
				margin-bottom: 0.3rem;
			}
			&:nth-of-type(2) {
				font-size: 1.4rem;
				color: #787878;
				margin-bottom: 0.3rem;
			}
			&:nth-of-type(3) {
				font-size: 1.4rem;
				color: #8681ff;
			}
		}
	}
}
.active {
	border-right: 3px solid #6c63ff;
}
