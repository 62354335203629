/** @format */

.container {
	padding: 1rem;
	width: 700px !important;
	max-width: 800px !important;
	display: flex;
	flex-direction: column;

	button {
		align-self: flex-end;
		width: 40% !important;
	}
}
.formFiledContainer {
	padding: 10px 1rem;
	margin-bottom: 1.5rem;
	border-radius: 10px;
	display: flex;
	align-items: center;
	font-family: "Nunito", sans-serif;
	position: relative;

	label {
		width: 50%;
		font-size: 1.6rem;
	}
}
