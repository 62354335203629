.SignInContainer {
  // background: url("../../assets/login-grapg.png") no-repeat right center;
  height: 100vh;
  width: 100%;
  .contentContainer {
    padding: 6rem 14rem;
    & > div {
      input {
        padding: 2px 4px;
      }
      h1 {
        font-size: 3.7rem;
        font-family: "Raleway", sans-serif;
        color: #211f57;
        font-weight: 800;
        margin-top: 12rem;
      }
      p {
        margin-top: 2rem;
        font-size: 1.6rem;
        font-family: "Nunito", sans-serif;
        color: #7a7a7a;
        font-weight: 400;
      }
    }
    button {
      margin-top: 2rem;
      background-color: #6c63ff !important;
      color: white !important;
      padding-left: 3rem;
      padding-right: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      width: 8vw;
      padding-top: 1rem;
      padding-bottom: 1rem;
      img {
        width: 2.5rem;
        margin-left: 2rem;
      }
    }
    a {
      margin-top: 2rem;
      background-color: #6c63ff !important;
      color: white !important;
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 8vw;
      cursor: pointer;
      img {
        width: 2.5rem;
        margin-left: 2rem;
      }
    }
  }
}
