/** @format */

.container {
	padding-top: 60px;
	h2 {
		font-size: 3rem;
		font-family: "Raleway", sans-serif;
		color: #15124e;
	}
}
