/** @format */

.candidateBox {
	display: flex;
	border-bottom: 1px solid #f3f7ff;
	cursor: pointer;

	&:hover {
		background: #f3f6ff;
	}

	& > div {
		&:nth-of-type(1) {
			padding: 1rem;
			border-right: 1px solid #f3f7ff;
		}
	}
	.candidateDetail {
		display: flex;
		padding: 1rem;
		flex-grow: 1;

		& > div {
			&:nth-of-type(1) {
				.userimg {
					height: 40px;
					width: 40px;
				}
				// padding: 1rem;
				// font-size: 1.6rem;
				// font-family: "Nunito", sans-serif;
				// background-color: turquoise;
				// border-radius: 50%;
				// align-self: center;
			}
			&:nth-of-type(2) {
				font-family: "Nunito", sans-serif;
				margin-left: 1rem;
				p {
					&:nth-of-type(1) {
						font-size: 1.4rem;
						font-weight: 600;
					}
				}
			}
			&:nth-of-type(3) {
				margin-left: auto;
				display: flex;
				align-items: center;
				margin-right: 2rem;
				.score {
					font-size: 1.5rem;
					padding: 1rem;
					margin-right: 1rem;
					border-radius: 50%;
					color: white;
					font-family: "Nunito", sans-serif;
					&.goodScore {
						background-color: #17af75;
					}
					&.averageScore {
						background-color: #f57f17;
					}
					&.badScore {
						background-color: #f44336;
					}
				}
				.statusContainer {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
					.status {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						&.active {
							background-color: #17af75;
						}
						&.inactive {
							background-color: #f44336;
						}
						&.completed {
							background-color: #f57f17;
						}
					}
				}
			}
		}
	}
}
.checkBox {
	width: 30px;
	height: 30px;
}

/* CSS classes for different status colors */
.userStatusBanner {
	padding: 4px 8px;
	text-align: center;
	border-radius: 4px;
	font-size: 12px;
	font-weight: bold;
	min-width: 70px;
	max-width:70px;
}

.yetToRespond {
	background-color: yellow;
	color: black;
	font-size: 12px;
}
  