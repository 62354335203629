/** @format */
.vacancyContainer {
	h2 {
		font-size: 15px;
		font-family: "Raleway", sans-serif;
		color: #15124e;
		padding: 20px 15px;
		border-bottom: 1px solid #f3f7ff;
	}
	&.hidden {
		transition: 2s all;
		transform: translateX(-300px);
	}
}
.formControl {
	width: 100%;
	label {
		font-size: 1.5rem !important;
		color: black;
		background-color: white;
	}
	.select {
		font-size: 1.6rem !important;
		color: rgba($color: #000000, $alpha: 0.5) !important;
		@media screen and (max-width: 1400px) {
			font-size: 1.2rem !important;
		}
	}
}
.menuitem {
	font-size: 1.6rem !important;
}
.postingBox {
	overflow-y: auto;
	height: calc(100vh - 125px);
	&.jobsLoading {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.filterBox {
	padding: 20px 15px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;
	border-bottom: 1px solid #f3f7ff;
}
.noVacancy {
	font-size: 2rem;
	text-align: center;
	font-family: "Raleway", sans-serif;
}
.loading {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	border-radius: 50%;
	border: 3px solid #f3f7ff;
	border-top: 3px solid #636cff;
	animation: rotate 1s infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
