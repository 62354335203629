/** @format */

.icon {
  img {
    width: 40px;
    height: 40px;
  }
}

.iconUser {
  img {
    width: 20px;
    height: 20px;
  }
}

.iconBot {
  img {
    width: 20px;
    height: 20px;
  }
}
.messageContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
}

.chatTitle {
  font-size: 1.5em;
  color: black;
  font-weight: bold;
}

.chatBox {
  background: #f3f7ff;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  &.chatBoxLoading {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  h5 {
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-size: 2rem;
    margin-top: 2rem;
  }
  .chatSubBox {
    margin: 2rem 1rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 250px);

    .chatContainer {
      font-family: "Nunito", sans-serif;
      align-self: flex-end;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-bottom: 1rem;
      & > div {
        margin-left: 1rem;
        align-self: flex-end;
      }
      & > p {
        font-size: 1.6rem;
        border-radius: 5px;
        background-color: #fff;
        padding: 1rem;
        max-width: 70%;
      }
    }
    .chatContainerBot {
      font-family: "Nunito", sans-serif;
      align-self: flex-start;
      display: flex;
      align-items: center;
      & > div {
        margin-right: 1rem;
        align-self: flex-end;
      }
      & > p {
        font-size: 1.6rem;
        border-radius: 5px;
        background-color: #fff;
        padding: 1rem;
        max-width: 70%;
      }

      margin-bottom: 1.5rem;
    }
  }
}
.userText {
}
.textBar {
  height: 73px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  .icons {
    padding: 1rem;
    background: #f3f6ff;
    border-radius: 50%;
    cursor: pointer;
    svg {
      color: #9e77ed;
      font-size: 2.5rem !important;
    }
  }
}
.inputBar {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 90%;
    padding: 1rem 0;
    border: none;
    outline: 0;
    font-size: 1.8rem;
    &::placeholder {
      font-size: 1.8rem;
    }
  }
}
.messageBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 25px;
  border-bottom: 1px solid #f3f7ff;
  .detail {
    display: flex;
    align-items: center;
    p {
      margin-left: 20px;
      color: #17af75;
      font-size: 1.2rem;
      font-family: "Nunito", sans-serif;
    }
  }
}
.menuButton {
  width: 22px;
  height: 30px;
  background: none;
  border-radius: 18px;
  &.active {
    border: 1px solid #4caf50;
  }
  &.inactive {
    border: 1px solid #f44336;
  }
  &.complete {
    border: 1px solid #f57f17;
  }
}
.menuItem {
  font-size: 1.6rem !important;
}

.loading {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top: 3px solid #636cff;
  animation: rotate 1s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
