/** @format */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
}

/**
 * Sets the height of the element to 100% of the viewport height.
 * 
 * @param {Length} height - The height of the element. Set to 100vh for full viewport height.
 */
.container {
  // margin-left: 87px;
}
.height-100vh {
  height: 100vh;
}
.PrivateTabIndicator-colorPrimary-2 {
  background-color: #6c63ff !important;
}
.MuiFilledInput-root {
  background-color: white !important;
  // box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.3);
}
.MuiTypography-body1 {
  font-size: 1.6rem !important;
}
.MuiFormControl-root {
  width: 100%;

  label {
    font-size: 1.5rem !important;
  }
  input {
    font-size: 1.5rem;
  }
}
.MuiButton-containedPrimary {
  width: 25% !important;
  span {
    font-size: 1.6rem;
  }
}
.MuiInput-underline::before,
.MuiInput-underline::after {
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}
.MuiInputBase-inputMultiline {
  font-size: 1.6rem !important;
}
.MuiButton-label {
  font-size: 1.6rem !important;
}
.MuiDialog-paperWidthSm {
  max-width: 800px !important;
}

.MuiAlert-root {
  position: fixed !important;
  z-index: 9999 !important;
  top: 10px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  font-size: 1.6rem !important;
}
.MuiTable-root {
  overflow-y: scroll !important;
  max-height: 1000px !important;
}
.MuiSvgIcon-root {
  font-size: 2rem !important;
}
.MuiFab-root:hover {
  background: #6c63ff !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #9ea9f0;
  border-radius: 500px;
}
::-webkit-scrollbar-thumb {
  background-color: #380e7f;
  border-radius: 500px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #380e7f;
  border-radius: 500px;
}

