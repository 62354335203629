/** @format */
.container {
}
/** @format */

.container {
	padding: 2rem;
	font-size: 1.6rem;
	font-family: "Nunito", sans-serif;
	width: 800px !important;
	max-width: 800px !important;
	display: flex;
	flex-direction: column;

	button {
		align-self: flex-end;
		width: 60% !important;
	}
}
.formFiledContainer {
	padding: 10px 1rem;
	margin-bottom: 1.5rem;
	border-radius: 10px;
	display: flex;
	align-items: center;
	font-family: "Nunito", sans-serif;
	position: relative;

	label {
		&.alignTop {
			align-self: flex-start;
		}
		width: 50%;
		font-size: 1.6rem;
	}
	.addButton {
		position: absolute;
		right: -20px;
	}
}

.menuitem {
	font-size: 1.6rem !important;
}

.formControl {
	width: 100%;
	label {
		font-size: 1.5rem !important;
		color: black;
		background-color: white;
	}
	.select {
		font-size: 1.6rem !important;
	}
}
.radioLabel {
}

.radioGroup {
	width: 50%;
	display: flex !important;
	flex-wrap: nowrap !important;
	flex-direction: row !important;
}
.radioGroupGrid {
	display: grid !important;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	width: 100%;
	& > div {
		display: flex;
	}
}
