/** @format */

.root {
	flex-grow: 1;
}
.container {
	width: calc(100%) !important;
	overflow: hidden !important;
}
.formFiledContainer {
	padding: 10px 1rem;
	margin-bottom: 1.5rem;
	border-radius: 10px;
	display: flex;
	align-items: center;
	font-family: "Nunito", sans-serif;
	width: 20%;

	&.biggerWidth {
		width: 100%;
	}

	label {
		&.alignTop {
			align-self: flex-start;
		}
		width: 50%;
		font-size: 1.6rem;
	}
}
.formControl {
	label {
		font-size: 1.5rem !important;
		color: black;
		background-color: white;
	}
	.select {
		font-size: 1.6rem !important;
	}
}
.menuitem {
	font-size: 1.6rem !important;
}
.filterFields {
	margin-top: 3rem;
	display: flex;
	justify-content: space-around;
}
.table {
	padding: 0 6rem;
}
.tableCell {
	font-size: 1.6rem !important;
	font-family: "Nunito", sans-serif !important;
	font-weight: 800 !important;
}
.tableCellBody {
	font-size: 1.6rem !important;
	font-family: "Nunito", sans-serif !important;
}
.icon {
	color: #6c63ff !important;
	cursor: pointer;

	&.deleteIcon {
		color: #d11a2a !important;
	}
}

.modifyContainer {
	padding: 5rem;
}
.formContainer {
	padding: 2rem 0;
}
.clearFilters {
	margin-top: 3rem;
}

.fullWidth {
	width: 100%;
}
.error {
	color: red !important;
	font-size: 1.4rem !important;
}
.screeningContainer {
	padding: 0 5rem;
	margin-bottom: 5rem;
}
.screeningHeader {
	display: flex;
	margin-bottom: 2rem;
	h2 {
		font-size: 2.6rem;
		font-family: "Nunito", sans-serif;
	}
	button {
		background-color: #6c63ff !important;
		color: #fff !important;
		margin-left: 2rem;
	}
}
.questionContainer {
	padding: 0 5rem;
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;

	& > div {
		width: 48%;
		h3 {
			font-size: 2rem;
			font-family: "Nunito", sans-serif;
		}
		padding: 1rem;
		display: flex;
		flex-direction: column;
		div {
			margin-bottom: 1rem;
			p {
				font-size: 1.6rem;
				font-family: "Nunito", sans-serif;
				padding: 1rem 0;
				border-bottom: 1px solid #00000025;
			}
		}
		button {
			justify-self: flex-end;
			align-self: flex-end;
			background-color: #6c63ff !important;
		}
	}
	button {
		align-self: flex-end;
		background-color: #6c63ff !important;
	}
}
.rangeControl {
	width: 100%;
	display: flex;
	justify-content: space-between;
	& > div {
		width: 45% !important;
	}
}
.buttons {
	display: flex;
	button {
		width: 25% !important;
		&:nth-of-type(1) {
			margin-right: 2rem;
		}
		&:nth-of-type(2) {
			border-color: #d11a2a;
			color: #d11a2a;
		}
	}
}
.deleteModal {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	p {
		font-size: 1.8rem;
		font-family: "Nunito", sans-serif;
		font-weight: 600;
	}
	& > div {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
		margin-top: 3rem;
		button {
			width: 30%;
			&:nth-of-type(1) {
				background-color: #d11a2a !important;
				color: #fff;
			}
			&:nth-of-type(2) {
				border-color: #d11a2a;
				color: #d11a2a;
			}
		}
	}
}
