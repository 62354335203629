/** @format */

.SelectionField {
	padding: 1rem 3rem;
	border: 1px solid #bab5ff;
	border-radius: 20px;
	background: white;
	label {
		color: #211f57;
		font-size: 1.6rem;
		font-family: "Nunito", sans-serif;
	}
}
.header {
	line-height: 1.5;
	padding-bottom: 1.6rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	h2 {
		font-size: 3rem;
		font-family: "Raleway", sans-serif;
		color: #15124e;
	}
	span {
		font-size: 1.6rem;
		font-family: "Nunito", sans-serif;
		color: #848484;
	}
}
.globalContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 24px;
	margin-bottom: 2rem;
}
.form {
	padding: 1.6rem 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	.formContainer {
		margin-right: 2rem;
		display: grid;
		grid-template-columns: 1fr;
		width: 50%;
		gap: 24px;
		& > div {
			padding: 1rem 3rem;
			border: 1px solid #bab5ff;
			border-radius: 20px;
			background: white;
			label {
				color: #211f57;
				font-size: 1.6rem;
				font-family: "Nunito", sans-serif;
			}
		}
	}
	.fab {
		background: rgb(108, 99, 255);
		align-self: center;
		margin-left: 5rem;
		.fabIcon {
			font-size: 4rem !important;
			color: #fff;
		}
	}
}
.list {
	padding: 40px 0 0 0;
	h2 {
		font-size: 3rem;
		font-family: "Raleway", sans-serif;
		color: #15124e;
	}
	.tableContainer {
		margin-top: 25px;
		table {
			box-shadow: 0 0 30px 0 rgba(108, 99, 255, 0.3);

			tbody {
				tr {
					td {
						font-size: 1.6rem !important;
						font-family: "Nunito", sans-serif;
						border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
						.icon {
							color: red;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	.buttonGroup {
		margin-top: 25px;
		.primary {
			background: rgb(108, 99, 255) !important;
			color: white !important;
			padding-left: 5rem;
			padding-right: 5rem;
		}
		.secondary {
			color: rgb(108, 99, 255) !important;
			border-color: rgb(108, 99, 255) !important;
			margin-left: 1rem;
			padding-left: 5rem;
			padding-right: 5rem;
		}
	}
}
.menuitem {
	font-size: 1.6rem !important;
}
.select {
	font-size: 1.6rem !important;
}

.MuiInput-underline:before {
	border-bottom: none !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: none !important;
}
.MuiInput-underline:after {
	border-bottom: none !important;
}

.warning {
	font-size: 2rem;
	margin-top: 25px;
	font-family: "Nunito", sans-serif;
}
.error {
	color: red !important;
	font-size: 1.4rem !important;
}
