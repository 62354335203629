/** @format */

.candidatesContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100vh;
	h2 {
		font-size: 15px;
		font-family: "Raleway", sans-serif;
		color: #9e77ed;
		padding: 20px 15px;
		margin-left: 2rem;
		border-bottom: 1px solid #f3f7ff;
	}
}
.actionBox {
	position: absolute;
	bottom: 0;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1rem;
	padding: 20px 15px;
	width: 100%;
	border-top: 1px solid #f3f7ff;
	z-index: 999;
	background-color: #fff;
	p {
		font-size: 1.2rem;
		text-align: center;
		font-family: "Raleway", sans-serif;
		font-weight: 700;
		line-height: 0.8;
		padding: 10px 0;
		border-radius: 5px;
		cursor: pointer;
		&:nth-of-type(1) {
			color: #4caf50;
			border: 2px solid #4caf50;
		}
		&:nth-of-type(2) {
			color: #f44336;
			border: 2px solid #f44336;
		}
		&:nth-of-type(3) {
			color: #f57f17;
			border: 2px solid #f57f17;
		}
		&:nth-of-type(4) {
			border: 2px solid #dc004e;
			color: #dc004e;
		}
		&:nth-of-type(5) {
			border: 2px solid #636cff;
			color: #636cff;
		}
		&:nth-of-type(6) {
			border: 2px solid #ded65e;
			color: #ded65e;
		}
	}
}
.candidateList {
	overflow-y: auto;
	flex: 1;
	&.loadingList {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.candidateListFull {
	overflow-y: auto;
	flex: 1;
	&.loadingList {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.formControl {
	width: 100%;
	label {
		font-size: 1.5rem !important;
		color: black;
		background-color: white;
	}
	.select {
		font-size: 1.6rem !important;
		color: rgba($color: #000000, $alpha: 0.5) !important;
		@media screen and (max-width: 1400px) {
			font-size: 1.2rem !important;
		}
	}
}
.menuitem {
	font-size: 1.6rem !important;
}

.filterBox {
	padding: 20px 15px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1.5rem;
	border-bottom: 1px solid #f3f7ff;
}

h6 {
	font-family: "Nunito", sans-serif;
	font-size: 1.6rem;
	padding: 2rem;
	text-align: center;
}
.loading {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	border-radius: 50%;
	border: 3px solid #f3f7ff;
	border-top: 3px solid #636cff;
	animation: rotate 1s infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.collapseBar {
	background-color: #9e77ed;
	font-size: 2rem;
	top: 10px;
	position: absolute;
	color: #fff;
	padding: 1rem 0;
	cursor: pointer;
	border-radius: 0 10px 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal {
	padding: 8rem;
	button {
		width: 25%;
		align-self: flex-end;
		margin-top: 2rem;
	}
	& > div {
		display: flex;
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
		&.buttonContainer {
			justify-content: space-around;
			margin-top: 4rem;
			& > button {
				width: 40% !important;
			}
		}
		& > p {
			font-size: 1.6rem;
			font-family: "Nunito", sans-serif;
			width: 50%;

			&:nth-of-type(2) {
				font-weight: 700;
			}
		}
	}
}
.Control {
	width: 30% !important;
	label {
		font-size: 1.5rem !important;
		color: black;
		background-color: white;
		@media screen and (max-width: 1400px) {
			font-size: 1.2rem !important;
		}
	}
	.select {
		font-size: 1.6rem !important;
		color: rgba($color: #000000, $alpha: 0.5) !important;
	}
}
.closeButton {
	position: absolute;
	cursor: pointer;
	right: 25px;
	top: 15px;
}
.SelectionField {
	padding: 1rem 3rem;
	background: white;
	display: flex;
	flex-direction: column;
	label {
		color: #211f57;
		font-size: 1.6rem !important;
		font-family: "Nunito", sans-serif;
	}
	div {
		margin-top: 0.2rem;
		div {
			div {
				font-size: 1.6rem !important;
			}
		}
	}
}

.nudge{
	display: flex;
	align-items: center;
	justify-content: center;
}
.error {
	color: red !important;
	font-size: 1.4rem !important;
}

.datePicker {
	padding-right: 10px;
	height: 15px;
	margin-bottom: 40px;
}

.datePickerContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.datePickerTitle {
	margin-bottom: 8px;
}

.candidates {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	width: 617px;
}

.schedule{
	font-size: medium;
}

.modalContainerLoading{
	display: flex;
    justify-content: center;
}