/** @format */

.root {
  flex-grow: 1;
}
.container {
  width: calc(100%) !important;
  overflow: hidden !important;
}
.item {
  padding: 6rem 5rem 5rem 5rem;
  display: flex;
  flex-direction: column;
  &:nth-of-type(1) {
    margin-right: 1.5rem;
  }
  &:nth-of-type(2) {
    margin-left: 1.5rem;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.error {
  color: red !important;
  font-size: 1.4rem !important;
}
.fullWidth {
  width: 100%;
}

.formControl {
  width: 100%;
  label {
    font-size: 1.5rem !important;
    color: black;
    background-color: white;
  }
  .select {
    font-size: 1.6rem !important;
    color: rgba($color: #000000, $alpha: 0.5) !important;
  }
}

.menuitem {
  font-size: 1.6rem !important;
}
.textfield {
  width: 100%;
  font-size: 1.5rem;
  label {
    font-size: 1.5rem;
  }
}

.formFiledContainer {
  padding: 10px 1rem;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: "Nunito", sans-serif;
  position: relative;

  label {
    &.alignTop {
      align-self: flex-start;
    }
    width: 50%;
    font-size: 1.6rem;
  }
  .addButton {
    position: absolute;
    right: -20px;
  }
}

.chip {
  background-color: #15124e !important;
  color: #fff !important;
  font-size: 1.2rem !important;
  font-family: "Nunito", sans-serif !important;
}
.icon {
  font-size: 24px !important;
  margin-bottom: 2rem !important;
  cursor: pointer;
  &.iconLight {
    opacity: 0.8;
    color: #6c63ff;
  }
}

.screeningContainer {
  padding: 0 5rem;
  margin-bottom: 5rem;
}
.screeningHeader {
  display: flex;
  margin-bottom: 2rem;
  h2 {
    font-size: 2.6rem;
    font-family: "Nunito", sans-serif;
  }
  button {
    background-color: #6c63ff !important;
    color: #fff !important;
    margin-left: 2rem;
  }
}
.loading {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: 3px solid #f3f7ff;
  border-top: 3px solid #636cff;
  animation: rotate 1s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadContainer {
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.rangeControl {
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    width: 45% !important;
  }
}
.dropzone {
  cursor: pointer;
  width: 100%;
  text-align: center;
  height: 100px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #272380;
  font-size: 1.5rem;
  font-family: "Nunito", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 13rem;
}

.dropTemplate {
  cursor: pointer;
  width: 100%;
  text-align: center;
  height: 100px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #272380;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: "Nunito", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.questionContainer {
	padding: 0 5rem;
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;

	& > div {
		width: 100%;
		h3 {
			font-size: 2rem;
			font-family: "Nunito", sans-serif;
		}
		padding: 1rem;
		display: flex;
		flex-direction: column;
		div {
			margin-bottom: 1rem;
			p {
				font-size: 1.6rem;
				font-family: "Nunito", sans-serif;
				padding: 1rem 0;
				border-bottom: 1px solid #00000025;
				display: flex;
				align-items: center;
				span {
					display: flex;
					justify-content: space-between;
					.deleteIcon {
						color: #d11a2a !important;
						cursor: pointer;
					}
					.editIcon {
						color: #6c63ff !important;
						cursor: pointer;
					}
				}
			}
		}
		button {
			justify-self: flex-end;
			align-self: flex-end;
			background-color: #6c63ff !important;
		}
	}
	button {
		align-self: flex-end;
		background-color: #6c63ff !important;
	}
}
.checkBox {
	width: 30px;
	height: 30px;
}

.deleteModal {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	p {
		font-size: 1.8rem;
		font-family: "Nunito", sans-serif;
		font-weight: 600;
	}
	& > div {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
		margin-top: 3rem;
		button {
			width: 30%;
			&:nth-of-type(1) {
				background-color: #d11a2a !important;
				color: #fff;
			}
			&:nth-of-type(2) {
				border-color: #d11a2a;
				color: #d11a2a;
			}
		}
	}
}
.question{
	height: 250px;
    overflow-y: overlay;
}