/** @format */

.container {
  // width: calc(100% - 87px) !important;
  width: 100% !important;
  overflow: hidden !important;
}

.bar {
  border-right: 3px solid #f3f7ff;
}
