/** @format */

.profileContainer {
	display: flex;
	flex-direction: column;
	height: 95vh;
	.profileHeader {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 4rem 0 3rem 0;
		border-bottom: 3px solid #f3f7ff;

		p {
			color: #888888;
			font-size: 1.4rem;
			font-family: "Nunito", sans-serif;
			&:nth-of-type(1) {
				font-size: 1.8rem;
				font-weight: 700;
			}
		}

		.outerCircle {
			position: relative;
			margin-bottom: 2rem;
			img {
				width: 120px;
				height: 120px;
			}
			.innerCircle {
				position: absolute;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: red;
				border: 2px solid white;
				bottom: 0;
				right: 0;
			}
		}
	}
	.headerInfo {
		flex-grow: 1;
		.Information {
			padding: 3rem;
			border-bottom: 3px solid #f3f7ff;
			h2 {
				font-size: 1.8rem;
				font-weight: 700;
				font-family: "Raleway", sans-serif;
				color: #272380;
				margin-bottom: 1rem;
			}
			p {
				font-family: "Nunito", sans-serif;
				color: #686868;
				font-size: 1.4rem;
			}
		}
		.notes {
			padding: 3rem;
			border-bottom: 3px solid #f3f7ff;
			h4 {
				font-size: 1.4rem;
				font-weight: 600;
				color: #15124e;
				font-family: "Raleway", sans-serif;
				margin-bottom: 1rem;
			}
		}
		.actions {
			padding: 3rem;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 1rem;
			text-align: center;
			border-bottom: 3px solid #f3f7ff;
			p {
				font-size: 1.4rem;
				font-family: "Nunito", sans-serif;
				padding: 0.6rem 0;
				border-radius: 8px;
				cursor: pointer;

				&:nth-of-type(1) {
					border: 2px solid #4caf50;
					color: #4caf50;
					&.schedule {
						background-color: #4caf50;
						color: white;
					}
				}
				&:nth-of-type(2) {
					border: 2px solid #f44336;
					color: #f44336;
					&.disqualify {
						background-color: #f44336;
						color: white;
					}
				}
				&:nth-of-type(3) {
					border: 2px solid #f57f17;
					color: #f57f17;
					&.assess {
						background-color: #f57f17;
						color: white;
					}
				}
				&:nth-of-type(4) {
					border: 2px solid #dc004e;
					color: #dc004e;
					&.reference {
						background-color: #dc004e;
						color: white;
					}
				}
				&:nth-of-type(5) {
					border: 2px solid #636cff;
					color: #636cff;
					&.shortlist {
						background-color: #636cff;
						color: white;
					}
				}
				&:nth-of-type(6) {
					border: 2px solid #ded65e;
					color: #ded65e;
					&.shortlist {
						background-color: #ded65e;
						color: white;
					}
				}
			}
		}
		.files {
			padding: 3rem;
			h2 {
				font-size: 1.8rem;
				font-weight: 700;
				font-family: "Raleway", sans-serif;
				color: #272380;
				margin-bottom: 1rem;
			}
			svg {
				font-size: 2.5rem !important;
			}
		}
	}
}
.modal {
	width: 500px !important;
	max-width: 500px !important;
	padding: 3rem;
	display: flex;
	flex-direction: column;
	h4 {
		font-size: 1.6rem;
		font-family: "Nunito", sans-serif;
		margin-bottom: 2rem;
	}
	& > div {
		display: flex;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
		label {
			font-size: 1.4rem;
			width: 40%;
			font-family: "Nunito", sans-serif;
		}
	}
	button {
		align-self: flex-end;
	}
}
.resumeLink {
	color: #636cff;
}

.filesBtn{
	border: 2px solid #636cff !important;
	width: 180px !important;
	color: #636cff !important;
}

.disableFilesBtn{
	border: 2px solid #a9adfb !important;
	width: 180px ;
	color: #a9adfb !important;
}

.filesUl{
	list-style-type: none;
}
.filesLi{
	margin-bottom: 10px;
}

.mediaContainer {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
	grid-gap: 16px;
	padding-bottom: 15px;
}

.mediaContainer img {
	width: 320px;
	height: 200px;
}

.mediaItem {
	position: relative;
	margin-bottom: 25px;
}

.videoWrapper {
	position: relative;
	width: 320px;
	height: 200px;
}

.videoWrapper video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.videoText {
	width: 200px;
	position: absolute;
	text-align: center;
	padding: 8px;
	cursor: pointer;
	background-color: #636cff;
}

.videoText p {
	font-size: 10px;
	// color: #636cff;
	color: white;
}

.loading {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	border-radius: 50%;
	border: 3px solid #f3f7ff;
	border-top: 3px solid #636cff;
	animation: rotate 1s infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}