/** @format */

.heading {
	text-align: center;
	font-size: 2rem;
	font-family: "Nunito", sans-serif;
}
.questionContainer {
	padding: 0 5rem;
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;

	& > div {
		width: 100%;
		h3 {
			font-size: 2rem;
			font-family: "Nunito", sans-serif;
		}
		padding: 1rem;
		display: flex;
		flex-direction: column;
		div {
			margin-bottom: 1rem;
			p {
				font-size: 1.6rem;
				font-family: "Nunito", sans-serif;
				padding: 1rem 0;
				border-bottom: 1px solid #00000025;
				display: flex;
				align-items: center;
				span {
					display: flex;
					justify-content: space-between;
					.deleteIcon {
						color: #d11a2a !important;
						cursor: pointer;
					}
					.editIcon {
						color: #6c63ff !important;
						cursor: pointer;
					}
				}
			}
		}
		button {
			justify-self: flex-end;
			align-self: flex-end;
			background-color: #6c63ff !important;
		}
	}
	button {
		align-self: flex-end;
		background-color: #6c63ff !important;
	}
}
.checkBox {
	width: 30px;
	height: 30px;
}

.deleteModal {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	p {
		font-size: 1.8rem;
		font-family: "Nunito", sans-serif;
		font-weight: 600;
	}
	& > div {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
		margin-top: 3rem;
		button {
			width: 30%;
			&:nth-of-type(1) {
				background-color: #d11a2a !important;
				color: #fff;
			}
			&:nth-of-type(2) {
				border-color: #d11a2a;
				color: #d11a2a;
			}
		}
	}
}
.question{
	height: 250px;
    overflow-y: overlay;
}
