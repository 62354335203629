.gaugeContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 6px;
//   column-gap: 2px;
  :first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  :last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.gauge {
  width: 19%;
  height: 100%;
  background-color: #ddd;
}

.gaugeActive {
  width: 20%;
  height: 100%;
  background-color: #7f56d9;
}
